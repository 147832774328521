import React from 'react';
import { graphql } from 'gatsby';

import Header from '../components/Header.jsx';
import Layout from '../components/Layout.jsx';
import ListAvailableDocuments from '../components/ListAvailableDocuments.jsx';
import Selector from '../components/Selector.jsx';

function App({ data, ifuId }) {
  const partPairs = data.allEifuPartPairings.edges
    .map(({ node }) => node)
    .sort((a, b) => a.partNumber > b.partNumber);
  const [selectedPart, setSelectedPart] = React.useState(ifuId || '');

  // dropdown only shows unique values
  // once one is selected, show avaiable versions to download
  return (
    <Layout>
      <Header />
      <Selector
        partPairs={partPairs}
        selectedPart={selectedPart}
        setSelectedPart={setSelectedPart}
      />
      {selectedPart && (
        <ListAvailableDocuments
          partPairs={partPairs}
          selectedPart={selectedPart}
          documents={data.allEifu.nodes}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  {
    allEifuPartPairings {
      edges {
        node {
          documentID
          partNumber
          revision
        }
      }
    }
    allEifu {
      nodes {
        majorVersion
        minorVersion
        versionCreated
        documentID
        language
      }
    }
  }
`;

export default App;
