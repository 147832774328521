import React from 'react';

import Logo from '../assets/logos/newTzLogo.svg';

function Header() {
  return (
    <center>
      <table>
        <tbody>
          <tr>
            <td>
              <img src={Logo} alt="TZ Logo" style={{ width: '100px' }} />
            </td>
            <td>
              <h1>TZ Medical Electronic Instructions For Use</h1>
            </td>
          </tr>
        </tbody>
      </table>
    </center>
  );
}

export default Header;
