/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

function Selector({ partPairs, selectedPart, setSelectedPart }) {
  // remove any duplicate partNumbers from partPairs
  const uniquePartPairs = partPairs.filter(
    (pairParts, index, self) =>
      index === self.findIndex((pair) => pair.partNumber === pairParts.partNumber)
  );

  const [partInput, setPartInput] = React.useState('');
  const props = {
    options: uniquePartPairs,
    getOptionLabel: (part) => part.partNumber || '',
  };

  const handleChange = React.useCallback(
    (_, newPart) => {
      setSelectedPart(newPart?.partNumber || '');
      setPartInput(newPart?.partNumber || '');

      // update url with new partNumber
      window.history.pushState({}, '', `/${newPart?.partNumber || ''}`);
    },
    [setSelectedPart]
  );

  const handleInputChange = React.useCallback(
    (_, newSelectedPart) => setPartInput(newSelectedPart),
    []
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Autocomplete
        sx={{ width: 300 }}
        value={selectedPart || ''}
        onChange={handleChange}
        inputValue={partInput || selectedPart}
        onInputChange={handleInputChange}
        id="flat-autocomplete"
        {...props}
        autoComplete
        autoHighlight
        clearOnEscape
        filterOptions={filterOptions}
        renderInput={(params) => <TextField {...params} label="Select an IFU" variant="standard" />}
      />
    </div>
  );
}

export default Selector;
