import React from 'react';

import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function ListAvailableDocuments({ partPairs, selectedPart, documents }) {
  // find all documents that match the selected part
  const availableDocuments = partPairs
    .filter(({ partNumber }) => partNumber === selectedPart)
    .map((pairing) => {
      const available = documents
        .filter(
          (doc) =>
            doc.documentID.toString() === pairing.documentID.toString() &&
            pairing.revision === `${doc.majorVersion}.${doc.minorVersion}`
        )
        .map((doc, index, allDocuments) => {
          // remove duplicates
          if (
            allDocuments.findIndex((otherDoc) => otherDoc.documentID === doc.documentID) === index
          ) {
            return [doc];
          }
          return [];
        })
        .flat();
      return available;
    })
    .flat()
    .map((document) => ({
      ...document,
      downloadUrl: `https://storage.googleapis.com/eifu-tzm/${document.documentID}/${document.majorVersion}/${document.minorVersion}/${document.documentID}.pdf`,
      fileName: `${selectedPart}_v${document.majorVersion}.${document.minorVersion}.pdf`,
    }))
    .sort((a, b) => new Date(b.versionCreated) - new Date(a.versionCreated));

  return (
    <TableContainer>
      <Table>
        <caption>
          Showing {availableDocuments.length === 0 ? 0 : 1} to {availableDocuments.length} of{' '}
          {availableDocuments.length} entries
        </caption>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Language</TableCell>
            <TableCell>Revision</TableCell>
            <TableCell>Published</TableCell>
            <TableCell>Download</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {availableDocuments.map((document, index) => (
            <TableRow
              key={`${document.documentID}|${document.versionCreated}|v${document.majorVersion}.${document.minorVersion}`}
            >
              <TableCell>Instructions For Use</TableCell>
              <TableCell>
                {document.language.charAt(0).toUpperCase() + document.language.slice(1)}
              </TableCell>
              <TableCell>
                v{document.majorVersion}.{document.minorVersion}
              </TableCell>
              <TableCell>{new Date(document.versionCreated).toDateString()}</TableCell>
              <TableCell>
                <IconButton>
                  <a
                    aria-label="Download"
                    href={document.downloadUrl}
                    download={document.fileName}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FileDownloadRoundedIcon />
                  </a>
                </IconButton>
              </TableCell>
              <TableCell>
                {index === 0 && (
                  <>
                    Printed Copy:
                    <a href="https://www.tzmedical.com/contact" target="_blank" rel="noreferrer">
                      <Button variant="text">Order</Button>
                    </a>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ListAvailableDocuments;
